// src/services/roleService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';

const getRoleConfig = async (role) => {
  const response = await axiosInstance.post(`${apiEndpoints.ROLECONFIG}/${role}`);
  return response.data;
};

const roleService = {
  getRoleConfig
};

export default roleService;
