import { Box, Typography } from "@mui/material";
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Logging error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h4">Something went wrong.</Typography>
          <details>{this.state.error && this.state.error.toString()}</details>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
