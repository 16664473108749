// src/router/AppRoutes.jsx
import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { OAuthRedirect, TokenExpiryCheck, AuthenticatedRoute } from '../config/AuthenticatedRoute';
import LoginPage from '../containers/Auth/LoginPage';
import LogoutCallback from '../components/LogoutCallback';
import Loader from '../components/Loader';
import { RoleProvider } from '../context/RoleContext';
import JobList from '../containers/Jobs/JobList';
const IssueList = React.lazy(() => import('../containers/Issues/IssueList'));

function AppRoutes() {
  return (
    <TokenExpiryCheck>
      <RoleProvider>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route
            path='/'
            element={
              <AuthenticatedRoute>
                <Suspense fallback={<Loader />}>
                  <IssueList />
                </Suspense>
              </AuthenticatedRoute>
            }
          />
          <Route path='/jobs' element={<JobList/>} />
          <Route path='/oauth/redirect' element={<OAuthRedirect />} />
          <Route path='/logout-callback' element={<LogoutCallback />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </RoleProvider>
    </TokenExpiryCheck>
  );
}

export default AppRoutes;
