// src/services/jobService.js
import axiosInstance from './axiosInstance';
import apiEndpoints from './apiEndpoints';
import { getRoleAndSiteCode } from '../utils/roleUtils';
import { getUniqueName } from '../config/AuthenticatedRoute';

const getJobs = async (page, isFilter, filters, pageSize, sortCriteria, defaultSort) => {
  const { role, siteCode } = getRoleAndSiteCode();
  const payload = {
    paginationParams: {
      page,
      pageSize,
      filterRequest: {
        filterParams: filters || [],
        filter: isFilter || false
      },
      sortingParams: {
        defaultSort,
        sortingKey: sortCriteria || {}
      }
    },
    appUserDetails: {
      role,
      siteCode
    }
  };

  const response = await axiosInstance.post(apiEndpoints.JOBLIST, payload);
  return response.data;
};

const getIssuesByJobId = async (jobId) => {
  const response = await axiosInstance.post(`${apiEndpoints.ISSUELISTBYJOB}/${jobId}`);
  return response.data;
};

const getAllJobStatus = async () => {
  const response = await axiosInstance.get(`${apiEndpoints.GETALLJOBSTATUS}`);
  return response.data;
};

const updateJobStatus = async (jobId, statusId) => {
  const username = getUniqueName();
  const payload = {
    jobId: jobId,
    statusId: statusId,
    updatedBy: username
  };
  const response = await axiosInstance.post(`${apiEndpoints.UPDATEJOBSSTATUS}`, payload);

  return response.data;
};

const jobService = {
  getJobs,
  getIssuesByJobId,
  getAllJobStatus,
  updateJobStatus
};

export default jobService;
