// src/components/PaginationControls.jsx
import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Pagination,
  PaginationItem
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon
} from "@mui/icons-material";

const PaginationControls = ({ rowsPerPage, handleRowsPerPageChange, totalPages, currentPage, handlePageChange }) => {
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" paddingY={2}>
      <FormControl variant="outlined" sx={{ width: "90px" }} size="small">
        <InputLabel>Entries</InputLabel>
        <Select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          label="Rows"
          aria-labelledby="Rows"
          sx={{ fontFamily: "ford f1 regular", fontWeight: "bolder" }}
        >
          {[10, 20, 30, 40, 50].map((rows) => (
            <MenuItem sx={{ fontFamily: "ford f1 regular", fontWeight: "bolder" }} key={rows} value={rows}>
              {rows}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Pagination
        data-testid="pagination"
        style={{ marginLeft: '30%' }}
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        color="primary"
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            sx={{ fontFamily: "ford f1 regular", fontWeight: "bolder" }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Box>
  );
};

export default PaginationControls;