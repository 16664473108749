// src/services/apiEndpoints.js
const apiEndpoints = {
  // Add other endpoints here
  ISSUELIST: '/leads',
  SEARCHVIN: '/vins',
  FILTERVALUES: '/distinctFilterLeadsValues',
  UPDATEISSUESSTATUS: '/alert',
  ISSUES: '/alertDetails',
  ROLECONFIG: '/roleConfig',
  JOBLIST: '/jobs',
  ISSUELISTBYJOB: '/issuesByJobId',
  GETALLJOBSTATUS: '/getAllStatus',
  UPDATEJOBSSTATUS: 'updateJobStatus'
};

export default apiEndpoints;
