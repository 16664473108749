import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  isTokenValid,
  clearTokens,
  getToken,
  storeTokens,
  parseJwt,
  isValidUrl
} from '../utils/auth';
import Loader from '../components/Loader';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

export function AuthenticatedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    const validateToken = async () => {
      const valid = await isTokenValid();
      setIsAuthenticated(valid);
    };

    validateToken();
  }, []);

  if (isAuthenticated === null) {
    return <Loader />; // Or any loading spinner
  }

  return isAuthenticated ? children : <Navigate to='/login' />;
}

export function OAuthRedirect() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchParams = new URLSearchParams(window.location.hash.replace('#', ''));

  const accessToken = searchParams.get('access_token');
  const expiresIn = searchParams.get('expires_in');
  const idToken = searchParams.get('id_token');

  if (accessToken && idToken && expiresIn) {
    storeTokens(accessToken, idToken, expiresIn);
  } else {
    console.error('Tokens are missing in the OAuth redirect response.');
  }

  const redirectPath = decodeURIComponent(query.get('origin_path'));
  return <Navigate to={redirectPath} />;
}

export function LoginWithAdfs() {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const resource = process.env.REACT_APP_RESOURCE;
  // Validate and sanitize window.location.origin, window.location.pathname, and window.location.search
  const validOrigin = new URL(DOMPurify.sanitize(window.location.origin)).origin;
  const validPathname = encodeURIComponent(DOMPurify.sanitize(window.location.pathname));
  const validSearch = encodeURIComponent(DOMPurify.sanitize(window.location.search));
  const redirectUri = DOMPurify.sanitize(
    `${validOrigin}/oauth/redirect?origin_path=${validPathname}${validSearch}`
  );
  const nonce = uuidv4(); // Generate a unique nonce value

  // Sanitize and encode query parameters
  const queryParams = new URLSearchParams({
    client_id: clientId,
    resource: resource,
    response_type: 'token id_token',
    redirect_uri: redirectUri,
    scope: 'openid',
    response_mode: 'fragment',
    nonce: nonce,
    'x-client-SKU': 'MSAL.JS',
    'x-client-ver': '1.0.0'
  }).toString();

  const oauthIssuerUrl = process.env.REACT_APP_OAUTH_URL;

  if (isValidUrl(oauthIssuerUrl)) {
    // Sanitize the URL using DOMPurify
    const sanitizedUrl = DOMPurify.sanitize(`${oauthIssuerUrl}?${queryParams}`);
    window.location.href = sanitizedUrl;
  } else {
    console.error('Invalid OAuth issuer URL');
  }
}

export function TokenExpiryCheck({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      const valid = await isTokenValid();
      if (!valid) {
        clearTokens();
        localStorage.clear();
        navigate('/login');
      }
    };

    checkTokenValidity();
  }, [navigate]);

  return children;
}

export const getUniqueName = () => {
  const token = getToken();
  const parsedToken = parseJwt(token);
  const username = parsedToken['sub'];
  return username;
};

export const getUserName = () => {
  const token = getToken();
  const parsedToken = parseJwt(token);
  let username = ''; // Define username outside the try block

  try {
    const givenName = parsedToken['givenName'] || '';
    const surname = parsedToken['sn'] || '';
    username = `${givenName} ${surname}`;
  } catch (error) {
    console.error('Error parsing token:', error);
    username = ''; // Assign username in the catch block
  }

  return username;
};
