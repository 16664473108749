// src/utils/jwks.js
import { importJWK } from 'jose';

let cachedJwks = null;
let jwksPromise = null;

export const getJwks = async () => {
  if (cachedJwks) {
    return cachedJwks;
  }

  if (jwksPromise) {
    return jwksPromise;
  }

  jwksPromise = (async () => {
    try {
      const jwksResponse = await fetch(process.env.REACT_APP_JWKS_URI);
      if (!jwksResponse.ok) {
        throw new Error('Failed to fetch JWKS');
      }
      const jwks = await jwksResponse.json();
      cachedJwks = await importJWK(jwks.keys[0], 'RS256');
      return cachedJwks;
    } catch (error) {
      console.error('Error fetching JWKS:', error);
      cachedJwks = null; // Ensure cachedJwks is reset on error
      return null;
    } finally {
      jwksPromise = null; // Reset the promise
    }
  })();

  return jwksPromise;
};
