import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  Button,
  Menu,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  FileDownloadOutlined,
  DescriptionOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Loader from "../../components/Loader";
import { useRole } from "../../context/RoleContext";
import { hasPageAccess } from "../../utils/permissions";
import UnauthorizedPage from "../Auth/UnauthorizedPage";
import { titleCase } from "../../utils/stringUtils";
import jobService from "../../services/jobService";
import PaginationControls from '../../components/PaginationControls';

const getPriorityStyles = (priority) => {
  const styles = {
    Urgent: { dotColor: '#BF152C', bgColor: '#FFF1F1', borderColor: '#BF152C' },
    High: { dotColor: '#FDA74C', bgColor: '#FFF1E1', borderColor: '#FDA74C' },
    Low: { dotColor: '#793FFF', bgColor: '#CAC7FF', borderColor: '#793FFF' },
    Medium: { dotColor: '#FF5AF8', bgColor: '#FFE3FE', borderColor: '#FF5AF8' }
  };
  return styles[priority] || { dotColor: '#000', bgColor: '#f9f9f9', borderColor: '#8B0000' };
};

const PriorityBadge = ({ priority }) => {
  const { dotColor, bgColor, borderColor } = getPriorityStyles(priority);
  return (
    <span style={{
      display: 'inline-flex',
      alignItems: 'center',
      padding: '2px 8px',
      backgroundColor: bgColor,
      borderRadius: '12px',
      border: `1px solid ${borderColor}`,
      fontFamily: 'ford f1 regular',
    }}>
      <span style={{
        display: 'inline-block',
        width: '8px',
        height: '8px',
        backgroundColor: dotColor,
        borderRadius: '50%',
        marginRight: '8px',
      }}></span>
      {priority}
    </span>
  );
};

const IssueTableRow = ({ issue, job, isLastIssue }) => (
  <TableRow key={issue.id}>
    <TableCell style={!isLastIssue ? { borderBottom: 'none' } : {}} colSpan={1} className="vertical-line"> </TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.customer}</TableCell>
    <TableCell title={job?.vin} style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job?.vin.slice(0, 20) + "..."}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.year}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{titleCase(job.model)}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{titleCase(job.trim)}</TableCell>
    <TableCell sx={{ textTransform: "capitalize", width: "30%" }} style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}} >
      <Box sx={{ display: "flex" }}>
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "bold",
            }}
            variant="h6"
            gutterBottom
          >
            <span
              style={{ border: "1px solid #000000", padding: "3px", borderRadius: "5px" }}
            >
              {issue?.alertCategory === "Prognostics" ? issue?.alertCategory.slice(0, 4).toUpperCase() : issue?.alertCategory}
            </span>
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "8px" }}>
          {issue.alertTitle}
        </Box>
      </Box>
    </TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>
      <PriorityBadge priority={issue.severity} />
    </TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.assignment}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.assignment}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.timeElapsed}</TableCell>
    <TableCell style={!isLastIssue ? { borderBottom: '1px dashed rgba(163, 163, 163, 0.462)' } : {}}>{job.assignment}</TableCell>
  </TableRow>
);

const JobTableRow = ({ job, expandedRows, handleExpandClick, issues, handleStatusChange, getAllJobStatus }) => {
  return (
    <>
      <TableRow sx={{
        borderTop: expandedRows.includes(job.jobId) ? '1px dashed black' : 'none',
        background: expandedRows.includes(job.jobId) ? "#DDEAF8" : "transparent"
      }}>
        <TableCell style={{ width: "10%", padding: "0" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <button className="expand-btn" style={{ fontSize: '12px' }} onClick={() => handleExpandClick(job.jobId)}>
                {expandedRows.includes(job.jobId) ? '▼' : '▶'}
              </button>
            </Box>
            <Box>
              <FormControl variant="outlined" size="small">
                <Select
                  value={job?.status}
                  onChange={(event) => {
                    const newStatus = event.target.value;
                    const selectedStatus = getAllJobStatus.find(status =>
                      status.status === newStatus && status.jobTypeId === job?.jobTypeId
                    );
                    handleStatusChange(job?.jobId, newStatus, selectedStatus?.statusId);
                  }}
                  label="Status"
                  sx={{
                    fontSize: '12.8px',
                    marginLeft: '-15px',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outline: 'none'
                    }
                  }}
                >
                  {getAllJobStatus?.length && getAllJobStatus.filter((jobStatus) => jobStatus?.jobTypeId === job?.jobTypeId)?.map((status) => (
                    <MenuItem key={status?.status} value={status?.status}>
                      {status?.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </TableCell>
        <TableCell>{job.customer}</TableCell>
        <TableCell title={job?.vin}>{job?.vin.slice(0, 20) + "..."}</TableCell>
        <TableCell>{job.year}</TableCell>
        <TableCell sx={{ textTransform: "lowercase", ':first-letter': { textTransform: "capitalize" } }}>{job.model}</TableCell>
        <TableCell sx={{ textTransform: "lowercase", ':first-letter': { textTransform: "capitalize" } }}>{job.trim ?? '-'}</TableCell>
        <TableCell sx={{ width: "30%" }}>{job.cta}</TableCell>
        <TableCell>
          <PriorityBadge priority={job.priority} />
        </TableCell>
        <TableCell>{job?.assignment}</TableCell>
        <TableCell>
          <DescriptionOutlined />
        </TableCell>
        <TableCell>{job?.timeElapsed}</TableCell>
        <TableCell sx={{ color: "#2480F1", minWidth: '140px' }}>
          view full details
        </TableCell>
      </TableRow>
      {expandedRows.includes(job.jobId) && issues[job.jobId] && (
        issues[job.jobId].length > 0 ? (
          issues[job?.jobId].map((issue, index) => (
            <IssueTableRow
              key={`${job.jobId}-${issue.id}`}
              issue={issue}
              job={job}
              isLastIssue={index === issues[job.jobId].length - 1}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={11} align="center">
              No issues found
            </TableCell>
          </TableRow>
        )
      )}
    </>
  );
};

const JobList = () => {
  const [state, setState] = useState({
    currentPage: 1,
    totalPages: 0,
    dataSize: 0,
    loading: false,
    jobs: [],
    error: null,
    rowsPerPage: 10,
    sortCriteria: { status: '', priority: '' },
    defaultSort: true,
  });

  const [expandedRows, setExpandedRows] = useState([]);
  const [issues, setIssues] = useState({});
  const [getAllStatus, setGetAllStatus] = useState([]);
  const [statusUpdatePopUp, setStatusUpdatePopUp] = useState(false);
  const [statusUpdatedData, setStatusUpdaedData] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterClose = () => {
    setAnchorEl(null);
    setSelectedFilter(null);
  };

  const handleCheckboxToggle = (filter, option) => {
    setState((prev) => ({
      ...prev,
      selectedFilters: {
        ...prev.selectedFilters,
        [filter]: prev.selectedFilters[filter].includes(option)
          ? prev.selectedFilters[filter].filter((item) => item !== option)
          : [...prev.selectedFilters[filter], option]
      }
    }));
  };

  const { roleConfig, loading: roleLoading } = useRole();
  const filterOptions = {
    STATUS: ['Open', 'Scheduled', 'Closed'],
    CUSTOMER: ['Customer 1', 'Customer 2', 'Customer 3'],
    JOB: ['Job 1', 'Job 2', 'Job 3'],
    PRIORITY: ['Urgent', 'High', 'Medium', 'Low'],
    ASSIGNMENT: ['Christine V.', 'James Jacobs', 'Henry Dodge']
  };

  const handlePageChange = async (event, page) => {
    setState(prev => ({ ...prev, currentPage: page }));
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = event.target.value;
    setState(prev => ({ ...prev, rowsPerPage: newRowsPerPage, currentPage: 1 }));
    fetchJobs(1, true, null, newRowsPerPage);
  };

  const fetchJobs = useCallback(async (page, isFilter = false, vinFilter = null, totalItems = null) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));
    let filters = [];
    try {
      const data = await jobService.getJobs(
        page,
        isFilter,
        filters,
        totalItems || state.rowsPerPage,
        state.sortCriteria,
        state.defaultSort
      );
      setState((prev) => ({
        ...prev,
        jobs: data.data.jobs,
        currentPage: data.data.pagination.currentPage,
        totalPages: data.data.pagination.totalPages,
        loading: false,
        dataSize: data.data.pagination.totalItems
      }));
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setState((prev) => ({
          ...prev,
          jobs: [],
          error: "User is not authorized to view data",
          loading: false
        }));
      } else {
        setState((prev) => ({ ...prev, jobs: [], error: "Failed to fetch Jobs", loading: false }));
      }
    }
  }, [state.rowsPerPage, state.sortCriteria, state.defaultSort]);

  useEffect(() => {
    fetchJobs(state.currentPage);
  }, [state.currentPage, state.rowsPerPage, fetchJobs]);

  useEffect(() => {
    const fetchIssues = async (jobId) => {
      try {
        const response = await jobService.getIssuesByJobId(jobId);
        setIssues(prevIssues => ({
          ...prevIssues,
          [jobId]: response.data
        }));
      } catch (error) {
        console.error('Error fetching issues for job ID', jobId, error);
        setIssues(prevIssues => ({
          ...prevIssues,
          [jobId]: []
        }));
      }
    };

    expandedRows.forEach(jobId => {
      if (!issues[jobId]) {
        fetchIssues(jobId);
      }
    });
  }, [expandedRows, issues]);

  useEffect(() => {
    const fetchAllStatus = async () => {
      try {
        const response = await jobService.getAllJobStatus();
        setGetAllStatus(response.data);
      } catch (error) {
        console.error('Error fetching all jobs for job ID', error);
      }
    };

    fetchAllStatus();
  }, []);

  const handleExpandClick = (id) => {
    setExpandedRows(expandedRows.includes(id) ? [] : [id]);
  };

  const handleStatusChange = async (jobId, newStatus, newStatusId) => {
    try {
      await jobService.updateJobStatus(jobId, newStatusId);
      setState((prevState) => ({
        ...prevState,
        jobs: prevState.jobs.map((job) =>
          job.jobId === jobId ? { ...job, status: newStatus } : job
        ),
      }));
      setStatusUpdaedData({ vin: jobId });
      setStatusUpdatePopUp(true);
    } catch (error) {
      console.error('Error updating job status:', error);
    }
  };

  const handleClose = () => {
    setStatusUpdatePopUp(false);
  };

  const hasAccess = hasPageAccess(roleConfig, 'list_job', 'read');

  if (roleLoading) {
    return <Loader />;
  }

  if (!hasAccess) {
    return <UnauthorizedPage />;
  }

  return (
    <Container data-testid="jobs-list-container" maxWidth={false}>
      <Box sx={{ padding: "20px 0" }}>
        <Typography variant="h5" sx={{ fontSize: '20px', fontWeight: 400, lineHeight: '24px' }}><b>Jobs</b> ({state?.dataSize})</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search a job, customer, VIN ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ fontSize: '14px' }} />
                </InputAdornment>
              ),
            }}
            sx={{ width: '300px', fontSize: '14px' }}
          />
          <Button variant="contained" sx={{ ml: 1, border: '1px solid #D1D5D9', boxShadow: 'none', fontSize: '14px', fontWeight: 500, lineHeight: '24px', color: '#000000', textTransform: 'capitalize', background: '#D1D5D9', '&:hover': { background: '#fff', color: '#000' } }}>Search</Button>
          <Button
            variant="contained"
            startIcon={<FilterAltOutlined />}
            data-testid="filter-button"
            sx={{
              backgroundColor: "white",
              color: "black",
              '&:hover': {
                backgroundColor: "white",
                color: "black",
              },
              marginRight: 1,
              marginLeft: 1,
              textTransform: "capitalize"
            }}
          >
            Filters
          </Button>
        </Box>
        <Button
          variant="contained"
          startIcon={<FileDownloadOutlined />}
          sx={{
            border: '1px solid #D1D5D9',
            boxShadow: 'none',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '24px',
            color: '#000000',
            background: '#fff',
            '&:hover': { background: '#d1d5d9' },
            textTransform: 'capitalize',

          }}
        >
          Export
        </Button>
      </Box>

      {state.loading ? (
        <Loader />
      ) : (
        <>
          <TableContainer component={Paper} sx={{ border: "none", boxShadow: "none" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {['Status', 'Customer', 'VIN', 'Year', 'Model', 'Trim', 'Job', 'Priority', 'Assignment', 'Notes', 'Time Elapsed', ''].map((header) => (
                    <TableCell key={header}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: 6 }}>{header}</span>
                        {header && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <KeyboardArrowUpOutlinedIcon sx={{ fontSize: '12px' }} />
                          <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '12px', marginTop: "-6px" }} />
                        </div>}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.jobs.map(job => (
                  <JobTableRow
                    key={job?.jobId}
                    job={job}
                    expandedRows={expandedRows}
                    handleExpandClick={handleExpandClick}
                    issues={issues}
                    handleStatusChange={handleStatusChange}
                    getAllJobStatus={getAllStatus}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            rowsPerPage={state.rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            totalPages={state.totalPages}
            currentPage={state.currentPage}
            handlePageChange={handlePageChange}
          />
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
        sx={{ '& .MuiPaper-root': { borderRadius: '8px', minWidth: '150px' } }}
      >
        {selectedFilter && filterOptions[selectedFilter].map((option) => (
          <MenuItem key={option} onClick={() => handleCheckboxToggle(selectedFilter, option)}>
            <Checkbox
              checked={state.selectedFilters[selectedFilter].includes(option)}
              sx={{
                '&.Mui-checked': {
                  color: '#00095B',
                },
                '& .MuiSvgIcon-root': {
                  borderRadius: '50%',
                }
              }}
            />
            {option}
          </MenuItem>
        ))}
      </Menu>

      {statusUpdatePopUp && (
        <Dialog
          open={statusUpdatePopUp}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle color={"#212121"} id="alert-dialog-title">
            {"Message"}
          </DialogTitle>
          <DialogContent>
            Status updated successfully for VIN - <b>{statusUpdatedData.vin}</b>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "white", background: "#212121", ":hover": { background: "#212121" } }} onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}

export default JobList;